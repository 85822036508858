import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {PageMap, Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {productWithVariantsMapper} from '@wix/wixstores-platform-common/dist/src/products/product.mapper';
import {IControllerErrorReporter} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {query as getFirstProductQuery} from '../graphql/getFirstProduct.graphql';
import {query as getProductBySlugQuery} from '../graphql/getProductBySlug.graphql';
import {query as storeMetaDataQuery} from '../graphql/getStoreMetaData.graphql';
import {graphqlOperation, GRAPHQL_SOURCE} from '../constants';
import {parseUrl} from '@wix/native-components-infra/dist/src/urlUtils';
import {GetFirstProductQueryVariables, GetProductBySlugQueryVariables} from '../graphql/queries-schema';
import {SPECS} from '../specs';

export function gqlStoreFrontQuery(siteStore: SiteStore, query: string, variables: {}, operationName: string) {
  let getFunc = siteStore.httpClient.post.bind(siteStore.httpClient);
  if (siteStore.experiments.enabled('specs.stores.GraphqlGet')) {
    getFunc = siteStore.tryGetGqlAndFallbackToPost.bind(siteStore);
  }
  return getFunc(siteStore.resolveAbsoluteUrl(`/${Topology.STOREFRONT_GRAPHQL_URL}`), {
    query,
    variables,
    source: GRAPHQL_SOURCE,
    operationName,
  });
}

export function gqlQuery(siteStore: SiteStore, query: string, variables: {}, operationName: string) {
  return siteStore.httpClient.post(siteStore.resolveAbsoluteUrl(`/${Topology.READ_WRITE_GRAPHQL_URL}`), {
    query,
    variables,
    source: GRAPHQL_SOURCE,
    operationName,
  });
}

export function getProductBySlug(siteStore: SiteStore, slug: string, externalId: string) {
  const productsQueryVariables: GetProductBySlugQueryVariables = {
    slug,
    externalId,
    withCountryCodes: siteStore.experiments.enabled(SPECS.CASHIER_EXPRESS_IN_PRODUCT_PAGE),
  };

  return gqlStoreFrontQuery(
    siteStore,
    getProductBySlugQuery,
    productsQueryVariables,
    graphqlOperation.GetProductBySlug
  );
}

export function getDefaultProduct(siteStore: SiteStore, externalId: string) {
  const productsQueryVariables: GetFirstProductQueryVariables = {
    limit: 1,
    sort: {sortField: 'CreationDate', direction: 'Descending'},
    filters: null,
    externalId,
    withCountryCodes: siteStore.experiments.enabled(SPECS.CASHIER_EXPRESS_IN_PRODUCT_PAGE),
  };

  return gqlStoreFrontQuery(
    siteStore,
    getFirstProductQuery,
    productsQueryVariables,
    graphqlOperation.GetDefaultProduct
  );
}

export function getStoreMetaData(siteStore: SiteStore) {
  return gqlQuery(siteStore, storeMetaDataQuery, {}, graphqlOperation.GetStoreMetaData);
}

async function fetchProduct({siteStore, fetchDefaultProduct}: {siteStore: SiteStore; fetchDefaultProduct: boolean}) {
  const encodedProductName = siteStore.location.path[siteStore.location.path.length - 1];
  const productName = decodeURIComponent(encodedProductName);
  const query = {
    method: 'post',
    headers: {Authorization: siteStore.instanceManager.getInstance()},
    body: JSON.stringify({
      query: {
        filter: JSON.stringify({slug: productName}),
      },
      includeVariants: true,
    }),
  };
  if (fetchDefaultProduct) {
    query.body = JSON.stringify({
      query: {
        sort: JSON.stringify([{numericId: 'desc'}]),
        paging: {limit: 1, offset: 0},
      },
      includeVariants: true,
    });
  }
  const catalogServerUrl = siteStore.experiments.enabled(SPECS.MIGRATE_CATALOG_SERVER)
    ? `catalog-reader-server`
    : `catalog-server`;
  const response = await fetch(siteStore.resolveAbsoluteUrl(`/_api/${catalogServerUrl}/api/v1/products/query`), query);
  if (response.status !== 200) {
    throw new Error('error in fetchProduct');
  }
  return (await response.json()).products[0];
}

export function getUrlWithoutParams(url: string): string {
  const parsedUrl = parseUrl(url);
  return `${parsedUrl.protocol}://${parsedUrl.host}${parsedUrl.path}`;
}

export async function wixCodeGetProduct(
  siteStore: SiteStore,
  reportError: IControllerErrorReporter,
  onSuccess: Function
) {
  const currentUrl = getUrlWithoutParams(siteStore.location.url);
  const sectionUrl = (await siteStore.getSectionUrl(PageMap.PRODUCT)).url;
  const useSlug =
    (siteStore.isPreviewMode() && siteStore.location.path.length > 1) ||
    (siteStore.isSiteMode() && currentUrl !== sectionUrl);
  const product = await fetchProduct({siteStore, fetchDefaultProduct: !useSlug}).catch(reportError);
  if (!product) {
    throw new Error('product not found');
  }
  onSuccess();
  return productWithVariantsMapper(product);
}
